/* eslint-disable max-len */
import { SVGProps } from '..';

export default function LineaLogo(props: SVGProps) {
  return (
    <svg width='200' height='208' viewBox='0 0 200 208' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_2303_633)'>
        <path d='M132.369 155.99H49.7001V68.8855H68.6148V139.109H132.369V155.981V155.99Z' fill='white' />
        <path
          d='M132.369 85.7576C141.687 85.7576 149.241 78.2038 149.241 68.8856C149.241 59.5674 141.687 52.0135 132.369 52.0135C123.05 52.0135 115.497 59.5674 115.497 68.8856C115.497 78.2038 123.05 85.7576 132.369 85.7576Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_2303_633'>
          <rect width='99.5407' height='103.977' fill='white' transform='translate(49.7001 52.0135)' />
        </clipPath>
      </defs>
    </svg>
  );
}
